.card {
    position: fixed;
    top: 25%;
    left: 25%;
    width: 25%;
    height: 25%;
    padding: 2rem;
    margin: 2rem;
    background-color: #fef9ef;
    border-radius: 1rem;
    border: 1px solid #d3d3d3;
  }

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    color: #227c9d;
    background-color: #fef9ef;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #227c9d;
    cursor: pointer;
}